declare const WEBPACK_GIT_REV: string;

import { lazyLoadValuSearch } from "@valu/react-valu-search/loader";

window.addEventListener("DOMContentLoaded", event => {
    lazyLoadValuSearch({
        // This should be the same as customer name in lowercase in valu search manager
        customer: "hamina",
        // Modify this if needed
        inputSelector: "#valu-search-input",
        openerSelector: "#valu-search-submit",
        rev: WEBPACK_GIT_REV,
    });
});
